<template>
  <el-row class="pagination-bar-wrap">
    <el-pagination background layout="total, prev, pager, next" :page-size="tableConfig.size" :total="tableConfig.total" @current-change="handleChangePageNumber"></el-pagination>
  </el-row>
</template>

<script>
export default {
  props: {
    tableConfig: {
      type: Object,
      default() {
        return {
          page: 1,
          size: 20,
          total: 0,
        };
      },
    },
  },
  methods: {
    handleChangePageNumber(page) {
      this.$emit('change:pageNumber', page);
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination-bar-wrap {
  height: 60px;
  margin-top: 20px;
  padding: 0 10px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  background: var(--main-pagination-background);
}
</style>
